import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import parse from "html-react-parser";
import breakpoints from "../../utils/breakpoints";
import { H5, Metadata } from "../typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Container = styled.div`
  position: relative;
  padding: 0 calc(var(--grid-padding) * 1.75) 0 calc(var(--grid-padding) * 0.75);
  z-index: 2;
  overflow: hidden;

  &.padding-top {
    padding-top: calc(var(--grid-padding) * 3.7);

    @media ${breakpoints.tablet} {
      padding-top: calc(var(--grid-padding) * 2.5);
    }
  }

  @media ${breakpoints.tabletSm} {
    padding: 0 calc(var(--grid-padding));
  }

  @media ${breakpoints.desktop} {
    padding: 0 calc(var(--grid-padding) * 1.83);
  }
`;

const SectionText = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0rem 0rem 6rem 0rem;
  background-color: var(--brand-white);

  .react-reveal {
    line-height:0;
  }

  @media ${breakpoints.tabletSm} {
    width: 52%;
    padding: 6rem 6rem 4rem 6rem;
  }

  @media ${breakpoints.tablet} {
    padding: 9rem 9rem 7rem 9rem;
  }

  @media ${breakpoints.desktopSm} {
    padding: 11rem 11rem 9.5rem 11rem;
  }

  @media ${breakpoints.monitor} {
    padding: 14rem 14rem 12.5rem 14rem;
  }
`;

const SectionHeadingWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 2.8rem;
`;

const SectionHeading = styled(H5)`
  margin: 0;
  text-align: left;
  font-family: var(--font-secondary);
  font-size: 2.6rem;
  line-height: 3.2rem;
  font-weight: 400;
  letter-spacing: -1.25px;
  color: var(--brand-gray);

  br:first-of-type {
    display: none;

    @media ${breakpoints.tablet} {
      display: block;
    }

    @media ${breakpoints.desktop} {
      display: none;
    }
  }

  @media ${breakpoints.tablet} {
    font-size: 3rem;
    line-height: 4rem;
  }

  @media ${breakpoints.desktop} {
    font-size: 4rem;
    line-height: 5rem;
  }

  @media ${breakpoints.monitor} {
    font-size: 5rem;
    line-height: 6rem;
  }
`;

const SectionEyebrow = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
  font-family: var(--font-primary);
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 auto 3rem 0;
  color: var(--brand-medium-gray);
  background-color: var(--brand-white);
  padding: 4rem 4rem 0 0;
  margin-top: -4rem;

  @media ${breakpoints.tablet} {
    font-size: 1rem;
    color: var(--brand-gray);
  }
  @media ${breakpoints.desktop} {
    font-size: 1.1rem;
    padding: 0rem 0rem 0 0;
    margin-top: 0rem;
  }
  @media ${breakpoints.monitor} {
    font-size: 1.2rem;
  }
`;

const SectionBody = styled.div`
  position: relative;
  text-align: left;
  font-family: var(--font-primary);
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 200;
  color: var(--brand-medium-gray);
  p {
    margin-bottom:0;
  }

  @media ${breakpoints.tablet} {
    font-size: 2.0rem;
    line-height: 3rem;
    color: var(--brand-gray);
  }
  @media ${breakpoints.desktop} {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
  @media ${breakpoints.monitor} {
    font-size: 2.6rem;
    line-height: 3.6rem;
  }
`;

const SectionImageWrapper = styled.div`
  position: relative;

  @media ${breakpoints.tabletSm} {
    width: 50%;
    height: max(46.8vh, 48rem);
    padding-bottom: 0;
  }

  @media ${breakpoints.tablet} {
    height: max(70vh, 64rem);
  }

  @media ${breakpoints.desktop} {
    height: max(73vh, 70rem);
  }

  @media ${breakpoints.monitor} {
    height: max(73vh, 90rem);
  }

  p {
    position: absolute;
    z-index: 1;
    top: 0;
    left: auto;
    right: calc(var(--grid-padding) * -1.75);
    width: 0.8em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0;
    padding-left: calc(var(--grid-padding) * 0.875);
    padding-right: calc(var(--grid-padding) * 0.875);
    font-family: var(--brand-secondary);
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 300;
    color: var(--brand-gray);
    letter-spacing: -0.2px;

    @media ${breakpoints.tabletSm} {
      left: auto;
      right: 100%;
      font-size: 1.6rem;
      line-height: 2rem;
      padding-left: calc(var(--grid-padding) / 2);
      padding-right: calc(var(--grid-padding) / 2);
    }

    @media ${breakpoints.desktop} {
      font-size: 1.8rem;
      line-height: 2.4rem;
      padding-left: calc(var(--grid-padding) * 0.91);
      padding-right: calc(var(--grid-padding) * 0.91);
    }

    span {
      display: inline-block;
      transform: rotate(90deg);
      white-space: nowrap;
      @media ${breakpoints.tabletSm} {
        transform: rotate(-90deg);
      }
    }

    strong {
      font-weight: 500;
    }
  }
`;

const SectionImage = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; 
  overflow: hidden;

  @keyframes imageReveal {
    0% {
      opacity: 0;
      transform: scale3d(1.05, 1.05, 1);
    }

    100% {
      transform: none;
      opacity: 1;
    }
  }
  picture {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    position: absolute;
    top: 0;
    left: 0; 
  }
  
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${props => props.imageFocus || 'top center'};
    position: absolute;
    top: 0;
    left: 0;

    @media ${breakpoints.tabletSm} {
      position: relative;
    }
  }
  & div {
    height: 100%;
    width: 100%;
  }

  @media ${breakpoints.tablet} {
    position: absolute;
  }
`;


const Section = styled.section`
  position: relative;
  margin-bottom: 0rem;

  @media ${breakpoints.tabletSm} {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12rem;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 14rem;
    &:last-of-type {
      margin-bottom: 19rem;
    }
  }

  &.align-left {
    @media ${breakpoints.tabletSm} {
      flex-flow: row;
    }
    ${SectionText} {
      @media ${breakpoints.tabletSm} {
        width: 57%;
        margin-left: -7%;
        padding-right:0;
      }
    }
  }
  &.align-right {
    @media ${breakpoints.tabletSm} {
      flex-flow: row-reverse;
      ${SectionImageWrapper} {
        p {
          right:auto;
          left: 100%;
          span {
            transform: rotate(90deg);
          }
        }
      }
    }
    ${SectionText} {
      @media ${breakpoints.tabletSm} {
        width: 57%;
        margin-right: -7%;
        padding-left:0;
      }
    }
  }
  &.full {
    display: block;
    width: 100%;
    
    ${SectionText} {
      width: 100%;
      min-height: 0;
      margin-top: 0rem;
      margin-left: 0;
      @media ${breakpoints.tablet} {
        width: 50%;
        margin-top: -11rem;
        padding-top: 11rem;
        padding-bottom: 0;
      }
    }
    ${SectionImageWrapper} {
      width: 100%;

      @media ${breakpoints.tabletSm} {
        height: max(46.8vh, 48rem);
      }
    
      @media ${breakpoints.tablet} {
        height: max(70vh, 64rem);
      }
    
      @media ${breakpoints.desktop} {
        height: max(73vh, 70rem);
      }
    
      @media ${breakpoints.monitor} {
        height: max(70vh, 94rem);
      }
    }

    &.align-left {
      ${SectionText} {
        padding-right: 6rem;
        @media ${breakpoints.tabletSm} {
          padding-left:0;
        }
      }
    }
  }

  &.auto {
    ${SectionImageWrapper} {
      &:after{
        content:'';
        display:block;
        padding-bottom: 116%;
      }
      @media (max-width: 1024px) {
        height:auto;
      }
      @media ${breakpoints.tablet} {
        &:after{
          display:none;
        }
      }
    }  
  }
  &.portrait {
    ${SectionImageWrapper} {
      height: auto;
      &:after{
        content:'';
        display:block;
        padding-bottom: 116%;
      }
    }
  }

  &.landscape {
    ${SectionImageWrapper} {
      width: 100%;
      height: auto;
      &:after{
        content:'';
        display:block;
        padding-bottom: 100%;
      }
      @media ${breakpoints.tablet} {
        width: 58%;
        &:after{
          padding-bottom: 79%;
        }
      }
    }
    &.align-left {
      ${SectionText} {
        @media ${breakpoints.tabletSm} {
          width: 49%;
          margin-left: -7%;
        }
      }
    }
    &.align-right {
      ${SectionText} {
        @media ${breakpoints.tabletSm} {
          width: 49%;
          margin-right: -7%;
        }
      }
    }
  }

  &:first-child {
    &:after {
      content:'';
      background-color: var(--brand-white);
      position: absolute;
      left:calc(100% - 7%);
      height: 100%;
      width: 100%;
      z-index: -1;
    }
  }
`;

export default function ZFlow({ sections, padding_top }) {
  return (
    <Container className={`${padding_top ? "padding-top" : ""}`}>
      {sections.map((section, i) => (
        <Section key={i} className={`${section.item_style.value} ${section.item_alignment.value} ${section.image_ratio?.value}`}>
          <SectionImageWrapper>
            {section.media_caption && <Metadata as="p"><span>{parse(section.media_caption || '')}</span></Metadata>}
            <SectionImage imageFocus={section.media.focus_css}>
              <Fade>
                <GatsbyImage image={getImage(section.media.imageFile)} alt={section.media_caption} />
              </Fade>
            </SectionImage>
          </SectionImageWrapper>
          <SectionText>
            <Fade bottom distance="1.5rem">
              <SectionEyebrow>{section.eyebrow}</SectionEyebrow>
              <SectionHeadingWrapper>
                <SectionHeading as="p">{section.heading}</SectionHeading>
              </SectionHeadingWrapper>
              <SectionBody dangerouslySetInnerHTML={{ __html: section.body_copy }}></SectionBody>
            </Fade>
          </SectionText>
        </Section>
      ))}
    </Container>
  );
}
