import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { withPrefix } from "gatsby";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import { H1, Metadata } from "../typography";
import breakpoints from "../../utils/breakpoints";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 54rem;
  overflow: hidden;
  margin-bottom: -3.5rem;

  @media ${breakpoints.tabletSm} {
    height: ${props => (props.theme === "light" ? "calc(100vh - "+props.headeroffset+"px)" : "100vh")};
    margin-bottom: -5rem;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: -8rem;
  }

  @media ${breakpoints.desktop} {
    margin-bottom: -11rem;
  }
`;

const Foreground = styled.div`
  position: relative;
  z-index: 2;
  margin-top: auto;
  width:100%;
  color: #fff;
  padding: 0 calc(var(--grid-padding) * 0.75) calc(var(--grid-padding) * 5);

  @media ${breakpoints.tabletSm} {
    padding-left: calc(var(--grid-padding));
    padding-right: calc(var(--grid-padding));
    padding-bottom: calc(var(--grid-padding) * 5);
  }

  @media ${breakpoints.tablet} {
    padding-bottom: calc(var(--grid-padding) * 2.5);
  }

  @media ${breakpoints.desktop} {
    padding: 0 calc(var(--grid-padding) * 1.83) calc(var(--grid-padding) * 3);
  }

  @media ${breakpoints.monitor} {
    padding: 0 calc(var(--grid-padding) * 1.83) calc(var(--grid-padding) * 4);
  }

  .react-reveal {
    opacity: 0;
  }

  h1 {
    font-size: 3.6rem;
    line-height: 1.22em;
    font-weight: 400;
    max-width: 85%;
    letter-spacing: -3px;

    span {
      display: inline;

      @media ${breakpoints.desktopSm} {
        display: block;
      }
    }

    @media ${breakpoints.tabletSm} {
      font-size: 5rem;
    }

    @media ${breakpoints.tablet} {
      font-size: 6rem;
    }

    @media ${breakpoints.desktopSm} {
      font-size: 7rem;
      line-height: 1.08em;
      max-width: 60%;
    }

    @media ${breakpoints.desktop} {
      font-size: 8rem;
    }

    @media ${breakpoints.monitor} {
      font-size: 10rem;
    }
  }

  p {
    margin-top: 1.5rem;
    font-weight: 300;
    margin-bottom: 0;

    @media ${breakpoints.desktopSm} {
      margin: 0 0 1rem 0;
    }

    strong {
      font-weight: 500;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .react-reveal {
    opacity: 0;
    height:100%;
  }

  > span {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background: linear-gradient(transparent, #000);
  }
`;

const BackgroundImage = styled.picture`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    display:block;
    position: initial;
    height: 100%;
  }

  .react-reveal {
    position: absolute;
    height:100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
`;

const BackgroundVideo = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow:hidden;

  .gatsby-image-wrapper {
    display:block;
    position: initial;
    height: 100%;
    width: 100%;
  }

  .react-reveal {
    position: absolute;
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    aspect-ratio: 16 / 9;
  }

  iframe,
  video {
    position: absolute; 
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    object-position: center center;
    aspect-ratio: 16 / 9;
  }
`;

export default function HeroHome({
  heading,
  headingMeta,
  imageAlt,
  imageFile,
  video,
  theme,
  mobileTheme
}) {
  const videoRef = useRef();
  const [headerOffset, setHeaderOffset] = useState(0);
  const [playing, setPlaying] = useState(true);

  useLayoutEffect(() => {
    let header = document.querySelector('header');
    if (header) {
      setHeaderOffset(header.offsetHeight);
    }
  }, []);
  useEffect(() => {
    let header = document.querySelector('header');
    const onResize = () => {
      if (header) {
        setHeaderOffset(header.offsetHeight);
      }
    }
    window.addEventListener("resize", onResize);
    return () => {
        window.removeEventListener("resize", onResize);
    }
  }, []);
  const playVideo = e => {
    setPlaying(true)
  };
  const pauseVideo = e => {
    setPlaying(false)
  };
  return (
    <Container theme={theme} mobiletheme={mobileTheme} headeroffset={headerOffset} onClick={() => playing ? pauseVideo() : playVideo()}>
      <Foreground>
        <Fade bottom distance="1.5rem" delay={800}>
          <div>
            <H1>{parse(heading || '')}</H1>
            {headingMeta && (
              <Metadata as="p">{parse(headingMeta || '')}</Metadata>
            )}
          </div>
        </Fade>
      </Foreground>
      <Background>
        <Fade delay={500} duration={2000}>
          <span></span>
        </Fade>
        <BackgroundImage>
          <Fade>
            <GatsbyImage loading="eager" image={getImage(imageFile)} alt={imageAlt} />
          </Fade>
        </BackgroundImage>
        {video && (
          <BackgroundVideo>
            <Fade>
              <ReactPlayer ref={videoRef} url={video} loop={true} controls={false} volume={0} width={"100%"} height={"100%"} playsinline={true} playing={playing} />
            </Fade>
          </BackgroundVideo>
        )}
      </Background>
    </Container>
  );
}
