import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import Cta from "../cta";
import breakpoints from "../../utils/breakpoints";
import { Link } from "gatsby";

// import Swiper core and required modules
import SwiperCore, { Autoplay,EffectFade,Navigation,Pagination,Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Autoplay,EffectFade,Navigation,Pagination,Controller]);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  overflow: hidden;
  background-color: var(--brand-gray);
  color: #FFF;

  .swiper-container {
    width: 100%;
    height: auto;
    --swiper-theme-color: #FFF;
    --swiper-navigation-size: 2.4rem;
    .swiper-slide {
      opacity: 0!important;
    }
    .swiper-slide-active {
      opacity: 1!important;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 32vh;
      &:after {
        opacity:0;
      }
      &:before {
        content:'';
        position: absolute;
        display: block;
        width: 1.7rem;
        height: 1.7rem;
        border-right: 3px solid #FFF;
        border-bottom: 3px solid #FFF;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%) rotate(135deg);
        transition: all 300ms ease-in-out 0ms;
      }

      @media ${breakpoints.mobile} {
        top: 43vh;
      }
      @media ${breakpoints.tablet} {
        top: 50%;
      }
    }
    .swiper-button-prev {
      left: var(--grid-padding);
    }
    .swiper-button-next {
      right: var(--grid-padding);
      &:before {
        left: 25%; 
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
`;

const Content = styled.div`
  position: relative;
  border-top: 0.2rem solid;
  border-bottom: 0.2rem solid;
  padding-top: 4rem;
  padding-bottom: 6rem;
  height: 100%;

  @media ${breakpoints.tabletSm} {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  @media ${breakpoints.tablet} {
    padding-top: 10rem;
    padding-bottom: 16rem;
  }
  @media ${breakpoints.desktop} {
    padding-top: 12rem;
    padding-bottom: 20rem;
  }

`;

const Blockquote = styled.div`
  position: relative;
  text-align:center;
  max-width: 78%;
  margin: 0 auto;
  padding-bottom: 2rem;
  @media ${breakpoints.tablet} {
    padding-bottom: 8rem;
    max-width: 85%;
  }
  @media ${breakpoints.desktop} {
    max-width: 90%;
  }
`;

const SwiperWrapper = styled.div`
  padding: 1rem 0;
  @media ${breakpoints.tablet} {
    padding: 1rem 0;
  }
`;

const Slide = styled.p`
  font-family: var(--font-secondary);
  font-size: 3.2rem;
  line-height: 4rem;
  letter-spacing: -2px;
  font-weight: 400;
  margin: 0;

  @media ${breakpoints.tablet} {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }
  @media ${breakpoints.desktop} {
    font-size: 8rem;
    line-height: 9.6rem;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  button {
    font-size: 1.4rem;
    line-height: 5rem;

    @media ${breakpoints.tablet} {
      font-size: 1.6rem;
      line-height: 6rem;
    }
  }
`;

const NavBarInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  list-style-type:none;
  padding-bottom: 5rem;

  @media ${breakpoints.tablet} {
    padding-bottom: 15rem;
  }

  li {
    &:last-of-type {
      margin-right: 0;
    }
    width: 0;
    overflow: hidden;
    @media ${breakpoints.tablet} {
      margin-right: 3rem;
      width: auto;
    }
    &.active {
      @media ${breakpoints.tablet} {
        display: block;
        width: auto;
      }
      justify-content: center;
      display: flex;
      width: 100%;
      button {
        color: var(--brand-grey);
      }
    }
  }
  button {
    text-transform: uppercase;
    font-family: var(--font-secondary);
    font-size: 1.0rem;
    line-height: 1.2rem;
    font-weight: 700;
    color: var(--brand-medium-gray);
    position: relative;
    width: 11.4rem;
    min-height: 5rem;
    border-bottom: 0.1rem solid #D4DADF;
    transition: 0.3s linear color;

    @media ${breakpoints.tablet} {
      width: 25rem;
      font-size: 1.2rem;
      line-height: 1.5rem;
      min-height: 5.3rem;
    }
    @media ${breakpoints.desktop} {
      width: 25rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
  .indicator {
    width: 0%;
    height: 0.1rem;
    background: linear-gradient(90deg, #E91711 5.69%, #EFD608 94.31%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    position: absolute;
    left: 0;
    bottom: -0.1rem;
    
    &.active {
      width: 100%;
      transition: 5s linear width;
    }
  }
`;

const BulletsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media ${breakpoints.tablet} {
    display: none;
  }
  margin-top: 8.5rem;
  @media ${breakpoints.tabletSm} {
    margin-top: 10rem;
  }
  span {
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    background: rgba(255, 255, 255, 0.35);
    border-radius: 50%;
    margin: auto;
  }
  button {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    &.bullet-active {
      span {
        background: #FFFFFF;
      }
    }
  }
`;
let vfTicker;

const Slider = ({
  vc_items
}) => {
  const SEC_PER_SLIDE = 500;
  if(typeof window !== "undefined") {
    //vhfix
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  const [activeSlide, setActiveSlide] = useState(null)
  const [controlledSwiper, setControlledSwiper] = useState(null)
  const activeItem = vc_items[activeSlide]

  const changeSlide = (targetIndex) => {
    clearInterval(vfTicker)
    setActiveSlide(targetIndex)
    vfTicker = setInterval(() => {
      setActiveSlide((slideIndex) => (slideIndex + 1) % vc_items.length)
    }, SEC_PER_SLIDE * 1000)
  };

  useEffect(() => {
    setActiveSlide(0)
    vfTicker = setInterval(() => {
      setActiveSlide((slideIndex) => (slideIndex + 1) % vc_items.length)
    }, SEC_PER_SLIDE * 1000)
    return () => {
      clearInterval(vfTicker)
      controlledSwiper?.destroy()
    }
  }, [controlledSwiper, vc_items])

  useEffect(() => {
    if (controlledSwiper) {
      controlledSwiper.slideTo(activeSlide)
    }
  }, [activeSlide, controlledSwiper])

  return (
    <Fade>
      <Container>
          <Content>
            <NavBarInner>
              {vc_items.map(({ link, item_title }, index) => (
                <li
                  className={`${activeSlide === index ? 'active' : ''}`}
                  key={index}
                >
                  <button
                    onClick={() => changeSlide(index)}
                    type="button"
                    className="text--eyebrow"
                  >
                    {item_title}
                    <span
                      className={`indicator ${activeSlide === index ? 'active' : ''}`}
                    />
                  </button>
                </li>
              ))}
            </NavBarInner>
            <div>
              <Blockquote>
                <SwiperWrapper>
                  <Swiper
                    effect="fade"
                    slidesPerView={1}
                    fadeEffect={{ crossFade: true }}
                    controller={{ control: controlledSwiper }}
                    onSlideChange={(e) => changeSlide(e.realIndex)}
                    onSwiper={setControlledSwiper}
                    autoplay={{
                      "delay": 5000,
                      "disableOnInteraction": false
                    }}
                  >
                    {vc_items.map((e) => (
                      <SwiperSlide key={e.item_title}>
                        <Slide>{e.quote}</Slide>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </SwiperWrapper>
                <ButtonWrapper>
                  <Fade key={activeItem?.link_label} bottom distance="1.5rem">
                    <Cta 
                      data-theme="outline" 
                      as={Link}
                      to={activeItem?.link}>
                      {activeItem?.link_label}
                    </Cta>
                  </Fade>
                </ButtonWrapper>
              </Blockquote>
            </div>
            <BulletsWrap>
            {vc_items.map((i, index) => (
              <button
                onClick={() => changeSlide(index)}
                aria-label={i.link_label}
                className={`${activeSlide === index ? 'bullet-active' : ''}`}
                key={index}
                type="button"
              >
                <span />
              </button>
            ))}
          </BulletsWrap>
          </Content>
      </Container>
    </Fade>
  );
}

export default Slider
